#waitingdiv{
 
    width:100vw;
   
    background-color: #E9E9E9;
  overflow: hidden;
  position: relative;
   
   }
   

   
.divvvwaiting {
    position: absolute;
    top: 10%;
    width: 100%;
    height: 100%;
  
    display: flex;
    flex-direction: row;
    
    
  
  }
  .back-imagewaiting .blur-filter {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
   
  }
  
  
  
  .back-imagewaiting {
    position: relative;
   
    width:100%;
    height:100%;
    transform: scaleX(-1);
  }
  

  @media only screen and (min-width:589px){
    .back-imagewaiting img {
      width:100vw;
       height: 100%;
      
     }
  }
  

  
  .logoimage{
    height: 90%;
    margin-left: -15%;
    margin-top:-5%;
  }

 

  @media only screen and (min-width:977px){
    #titlewait{
      font-weight: 800;
      font-size: 2.2vw;
      color:#ad1f23;
  }
  .waitcontent{
    color: black;
   
    width:fit-content;
    margin-top:15%;
    margin-left:15%;
    
    


}
  .waitcontent p{
    font-weight: 500;
    font-size: 1.7vw;
  }}



  @media only screen and (max-width:976px){
    #titlewait{
      font-weight: 800;
      color:#ad1f23;
     
  }
    .waitcontent{
      color: black;
     
      width:fit-content;
      margin-top:5%;
      margin-left:15%;
      
      
  
  
  }
    .waitcontent p{
      font-weight: 500;
      font-size: 2.9vw;
     
    }}

@media only screen and (min-width:978px){



  #divbuttons{
    display:flex;
    flex-direction:row;
  }
  .marginn{
    margin-left:2%
  }

  .waitingbuttons{
background-color: black;
color: white;
padding:1%;
border:none;
width:120px;cursor: pointer;
transition: 0.5s all ease;
display: flex;
justify-content: center;
    align-items: center;
  }

  .waitingbuttons:hover{
    width:120px;
    background-color:#939DA6;
    color: black;
    padding:1%;
    transition: 0.5s all ease;
    border:none;
      }}











      @media only screen and (max-width:976px){
        #divbuttons{
          display:flex;
          flex-direction:column;
        }
        .marginn{
          margin-top:2%
        }
      
        .waitingbuttons{
      background-color: black;
      display: flex;
justify-content: center;
    align-items: center;
      color: white;
      padding:2%;cursor: pointer;
      border:none;width:120px;
    
      transition: 0.5s all ease;width:vw
        }
      
        .waitingbuttons:hover{
          width:120px;
          background-color:#939DA6;
          color: black;
          padding:2%;
          transition: 0.5s all ease;
          border:none;
            }}


            @media only screen and (max-width:588px){
              .marginn{
                margin-top: 2%!important
              }
              .divvvwaiting {
                position: absolute;
                top: 10%;
                margin: 0 auto;
                width: 100%;
                height: 100%;
                text-align: center;
                /* display: flex; */
                flex-direction: row;
                text-align: center;
                display: unset;
            }
.logoimage{
  width:50%;
  height:unset;
  margin-left:-10%;
  margin-top: unset;
    /* margin-left: -15%; */
    /* margin-top: -5%;*/

}
            .waitcontent {
              color: black;
              width: -moz-fit-content;
              width: fit-content;
              margin-top: 5%;
              /* margin-left: 15%; */
              margin: 0 auto;}

              #divbuttons {
                /* display: flex; */
                display: unset;
                flex-direction: unset;
             
               }
               .waitingbuttons{
                 margin-left:2%;cursor: pointer;
                 display: flex;
justify-content: center;
margin:0 auto;
    align-items: center;
               }


          }

          @media only screen and (max-width:588px){
            .imageresponsive11{
              height: 100%;
              width: 166vw;
            }
          }
