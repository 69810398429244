#footerdiv{
    background-color:#072438;
    width:90%;
    height:210px;
    padding-left:5%;
    padding-right: 5%;


}

.insidediv{
   
    
    padding-top: 2%;

}

#ssss:hover{
    color:#ad1f23;
    
}
#clickme:hover{
    color:#ad1f23;
    transition: 0.5s all ease;
}
#clickmee{
    text-decoration: underline;
}
#clickmee:hover{
    color:#ad1f23;
    transition: 0.5s all ease;
    text-decoration: underline;
}


.divleft{
float:left;
color: white;
}

.divright{
    float: right;
    color: white;

}

.buttonshowcontact{
    color:white;
    text-decoration: none;
}
#paddings{
    padding:0;
}

.buttonshowcontact:hover{
    color:#ad1f23;
    transition:0.5s all;
}

.colorhover:hover{
    color:grey;
    transition:0.5s all;
}

.rights1{
   display: flex;
   flex-direction: row;
    justify-content:space-between;

}

.design1{
    color:white;
    text-align: right;
}

.design2{
    color:white;
    text-align: left;
}

@media only screen and (max-width:768px){
    .rights1{
        display:flex;
        flex-direction: column-reverse;
        justify-content: center;
    }
    
    .design1{
        color:white;
        text-align: center;
    }
    
    .design2{
        color:white;
        text-align: center;
       
    }
}



#Union_1{
    fill:#fff;
    transition: 0.3s all ease-in-out;
}
.hovereffect:hover  #Union_1{
    fill:#ad1f23;
    transition: 0.3s all ease-in-out;
}
#Path_93{
    fill:#fff;
}

.hovereffect:hover  #Path_93{
    fill:#ad1f23;
    transition: 0.5s all ease-in-out;
}

.hovereffectt{
    z-index: 2;
    color: #ad1f23;
    /* opacity: 30%; */
    transition: 0.5s all ease-in-out;
}

a.hovereffectt:hover {
    z-index: 2;
    color:#ad1f23;
    opacity: 30%;
    transition: 0.5s all ease-in-out;

}

#topbutton{
    z-index:2;
    position:fixed;
    bottom:5%;
    right:5%;
    transform:scale(3);
    color:#ad1f23;
  }



   
.scroll {
    opacity: 1;
    background-color: #ad1f23;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 55px;
    border: none;
    cursor: pointer;
    transition: 0.5s all ;
    
  
  }
  .scroll:hover{
    transition: 0.5s all ;
      opacity: 0.3;
  }
  
  .arrow-up {
    color: #072438;
    position: absolute;
    top: 50%;
    left: 47.9%;
    margin-top: -3px;
    margin-left: -9px;
  }

  .glyphicon {
    
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 900;
    line-height:0.5 ;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.glyphicon-chevron-up:before {
    content: "\005E";
    color:#072438;
    font-weight: 900;
    font-size: 35px;
    
}



