.navbar {
  background-color: transparent;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
 
  top: 0;
  z-index: 20;
  position: relative;
}

#getappbutton{
  line-height: 30px;
  margin-right: 1rem;
  background-color: black;
  color: white;
width:90px;
text-align: center;
justify-content: center;
cursor: pointer;
height: 32px;
margin-top:0.5%;




}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.nav-logo {
  color: black;
  align-items: center;
  margin-left: 5%;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  position: absolute;
  left:0;
 
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
  position: absolute;
  right: 0;
}

#nav-links {
  
 color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  
  &.white {
    color: white;
  }

}


.fa-code {
  margin-left: 1rem;
}

#nav-item {
  line-height: 40px;
  margin-right: 1rem;
  margin-top: 8%;
}

#nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

#nav-item:hover:after {
  width: 60%;
  margin:0 auto;
  background: #ad1f23;
   
  &.white {
    background: #ad1f23;
  }
}

#nav-item .active {
  color: #ad1f23;
  &.white {
    color: #ad1f23;
  }
 
}

.nav-icon {
  display: none;
}



@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 0px;
    left: -110%;
    opacity: 1;
    padding-top:30%;
    height: 100vh;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #939DA6;
    left: 0px;
    opacity: 1;
    padding-top:30%;
    transition: all 0.5s ease;
    z-index: 1;height:100vh;
    position: fixed;
  }
  #nav-item .active {
    color: #5A0a0b;
    border: none;
  }

  #getappbutton{
    margin:0 auto;

  }
  #nav-item{
    margin-right: 3em;
    margin-top: 0%;
    line-height: 0px;
  }

  #nav-item:hover:after {
    width: 0%;
    margin:0 auto;
    
  }
  
 


  #nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #ad1f23;
    z-index: 1;
  }
}


.container {
  margin: auto;
 
  position: relative;
  
}

.top {
  position: fixed;
  width: 100%;
  z-index: 2;
}





























.sec__one {
  position: relative;
  width: 100%;
  display: flex;
  padding: 60px 0;
}
.sec__one h1{
text-align: center;
display: block;
margin: 0 auto;
color: #07a8ff;
font-family: 'Inter', sans-serif;
}
.navBar .logo img {
float: left;
max-height: 120px;
}
.is-active {
color: black!important;
}

.navBar .col-md-3 {
z-index: 7;
}
ul.main-menu {
display: inline-flex;
list-style: none;
margin: 0px auto 0;
padding: 0;
float: right;
z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
padding-left: 0;
margin-left: 0;
}

li {
list-style-type: none;
}
.menu-item a {

line-height: 40px;

color: #545050;
margin: 27px 17px;
text-decoration: none;
cursor: pointer;
}
.header__middle__logo{
width: 20%;
display: inline-block;

}
.header__middle__menus {
width: 70%;
display: inline-block;

}
.menu-item.menu__qqqitem a{
margin: 27px 10px;
}
.header__middle__search {
width: 7%;
display: inline-block;

padding: 36px 3px 27px 3px;
border-left: 1px solid lavender;
}
.header__middle {
display: flex;
width: 100%;
float: left;
border-bottom: 1px dashed lavender;
position: relative;
}
.header__middle__logo > a > h1 {
color: #07a8ff;
text-transform: uppercase;
text-decoration: none;
margin-top: 33px;
}
/* .active{
color: #ff1800!important;
} */
.header__middle__menus span.text {
position: absolute;
right: 177px;
bottom: 10px;
font-size: 14px;
color: black;
}
.sub__menus__arrows{
position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
padding-top: 0px;
position: absolute;
top: 13px;
}
.sub__menus {
position: absolute;
display: none;
background: white;
border: 1px solid lavender;
width: 157px;
left: -60px;
padding: 2px 0 0 0;
z-index: 1000;

}

@media (max-width:767px) { 
.header__middle .active {color: black!important;}
}

@media (min-width:992px) { 
.sub__menus__arrows:hover .sub__menus{
  display: block;
}
.sub__menus__arrows__full:hover .sub__menus{
  display: block;
  z-index: 1000000;
}
}
.menu-item .sub__menus  a {
padding: 7px 0 0px 0;
margin: 7px 27px;
}
.menu-item:hover > a{
color: #ad1f23 !important;
transition: 0.5s all ;
}
.menu-item .sub__menus li:hover a{
color: #ad1f23 !important;
transition: 0.5s all ;
}

.header__middle__logo img{
max-width: 207px;
margin: 17px 0 0 0;
}


@media(max-width:991px){
.header__middle__logo {width: 77%;}
.header__middle__menus {width: 20%;}
.header__middle__logo img {max-width: 197px;margin: 27px 0 0 0;}
.main-nav .menubar__button:hover {background-color: #f44336;}
.main-nav .menu-item:hover > a {color: #07a8ff !important}
.main-nav .menubar__button {
display: block!important;
float: right;
background-color: #080808;
color: #fff;
padding: 6px 7px;
border-radius: 5px;
margin: 27px auto 0;
cursor: pointer;
position: relative;
z-index: 10037;
}
.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
display: block!important;
position: absolute;
left: 0;
z-index: 10007;
background-color: #d8f1ff;
border-bottom: 3px solid #ff3d00;
right: 0;
padding-bottom: 17px;
padding-top: 57px;
}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: #000000;}
.main-nav  ul.main-menu {display: none}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}

.nolist{
  list-style-type: none;
  margin:0
}

#nav-links:hover{
  color:#ad1f23;
  transition: 0.5s all;
}



@media only screen and (min-width:633px) and (max-width:960px){
  .nav-menu{
width: 100%;
border-top: 1pxsolid #fff;
position: absolute;
top: 0px;
left: -110%;
opacity: 1;
padding-top:30%;
height: 100vh;
transition: all 0.5s ease;
}

.nav-menu.active {
background: #939DA6;
left: 0px;
opacity: 1;
padding-top:30%;
transition: all 0.5s ease;
z-index: 1;height:100vh;
position: fixed;
}}


@media only screen and (min-width:645px) and (max-width:960px){
  .nav-menu{
padding-top:15%;
  }
  .nav-menu.active{
    padding-top:15%;
  }
}

@media only screen and (min-width:962px){
  .menu-item{
    margin-top: 8%;
  }
  

}

@media only screen and (max-width:960px){
  #nav-links{
    font-size: 30px;
  }
  .menu-item a {
   
    font-size: 27px;
}
  
}



#hiarrow:hover{
  opacity: 85%;
  transition: 0.5s all ease-in-out;
}
