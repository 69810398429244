
 .h1privacy, .h2privacy {
    font-weight: 700;
}

.h1privacy {
    font-size: 40px;
    line-height: 60px;
}


.h1privacy, .h2privacy, .h3privacy, .h4privacy, .h5privacy, .h6privacy, li {
    margin: 0 0 16px;
}

.h2privacy {
    font-size: 32px;
    line-height: 48px;
}


.ulprivacy{
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.h3privacy, .h4privacy {
    font-weight: 700;
}

.h3privacy {
    font-size: 24px;
    line-height: 36px;
}


li {
    display: list-item;
    text-align: -webkit-match-parent;
    list-style-type:unset;
}
