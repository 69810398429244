* {
  margin: 0;
  padding: 0;
 font-family: Speer-Font;
  
}

body {
 overflow-x:hidden;
}

html {
  scroll-behavior: smooth;

}

@font-face {
  font-family: "Speer-Font";
  src: url("fonts/BebasKai.ttf") format("truetype");
  
}


