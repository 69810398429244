@media only screen and (min-width:968px){
  .navbarrr {
    background-color: #072438;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    display: none;
    
  }
}


.menu-barsss {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: black;
}

.nav-menuuu {
  background-color: #072438;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: 850ms;
  
}

#displayx{
  visibility: hidden;
}
@media only screen and (max-width:967px){
  .navbarrr{
    display: "";
    background-color: #072438;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  #displayx{
   visibility: visible;
  }
  .nav-menuuu {
    background-color: #072438;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
  }
}
.nav-menuuu.activeee {
  left: 0;
  transition: 350ms;
  z-index: 1;
}

.nav-texttt {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 16px 8px 16px;
  list-style: none;
  height: 60px;
  margin-top: 10px;
  transition: 0.5s all ease-in-out;
}

.nav-texttt a {
  text-decoration: none;
  color:black;
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}
#whitecolor{
  color:white;
  transition: 0.5s all ease-in-out;
}



.nav-texttt:hover {
  background-color: #939DA6;
  transition: 0.5s all ease-in-out;
}


.nav-texttt:hover #whitecolor {
 color:#ad1f23;
 transition: 0.5s all ease-in-out;
}


.nav-menu-itemsss {
  width: 100%;
}
@media only screen and (min-width:968px){
.navbar-toggleee {
  
  display: none;
  
}}

@media only screen and (max-width:967px){
  .navbar-toggleee {
    background-color: #072438;
   
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
}

.span {
  margin-left: 5px;
}

#logoutt{
  position: absolute;
    
    margin-bottom: 0%;
   
    width: 87%;
}