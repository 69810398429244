.back-image2{
  margin-left:-0.2%;
}


#goalsdiv{
 
   
   
  overflow: hidden;
  position: relative;
 
   background-color: #E9E9E9;
   }
   






   
   
.divvvgoal {
   
    top: 10%;
    width: 100%;
    height: 100%;
    background-image: url(../Assets/Backgroundgoal-02.svg);
    object-fit: cover;
    
    
  
  }
  @media only screen and (max-width:768px){
    .divvvgoal{
      background-image: url(../Assets/Backgroundgoal-01.svg);
   
    }
  }




  
  
  .titlegoal{
      font-size: 2.4vw;
      font-weight: 700;
      margin-left:7vw;
      color:#ad1f23;
      
  }

  @media only screen and (max-width:588px){
    .titlegoal{
      font-size: 4vw;
      font-weight: 700;
      margin-left:7vw;
      color:#ad1f23;
      
  }
  }


  .Allgoals{
    width: 85vw;
    height:auto;
 
   overflow: hidden;
   overflow-x: hidden;
    margin: 0 auto;
  }

  .goals{
    width: 32.3%;
    margin:0.5%;
    float: left;
    height:100%;
   
    position: relative;
  }
  .goalsimages{
      width:100%;
      height: 112%;
   
      position: absolute;
      z-index: 2;
      visibility: hidden;
      transition: 0.4s all ease-in-out;
      opacity: 0;
  }

  .goalsimages1{
    width:100%;
    position: relative;
    margin-bottom: 0%;
  
   
    display: block;

   

}


.picdiv{
    transition: 0.5s all;
    position: absolute;
   
    z-index: 4;
    padding-left:3%;
    height:15vh;
    visibility: hidden;
    transition: 0.4s all ease-in-out;
    opacity: 0;
   
}

@media only screen and (min-width:978px){
.titlepic{
    font-size: 2vw;
    color: #072438;
   
}
.contentpic{
    font-size: 1.9vw;
    color: #072438;
  
}}



@media only screen and (max-width:977px){
  .titlepic{
      font-size: 1.7vw;
      color: #072438;
     
  }
  .contentpic{
      font-size: 1.7vw;
      color: #072438;
    
  }}
  



.goalsimagesdisplay{
    width:100%;
    height: 112%;
    transition: 0.4s all ease-in-out;
    position: absolute;
    z-index: 2;
    visibility: visible;
    opacity: 1;
}


.picdivdisplay{
    position: absolute;
   
    z-index: 4;
    padding-left:3%;
 
    visibility: visible;
    transition: 0.4s all ease-in-out;
    opacity: 1;
}


@media only screen and (min-width:969px) and (max-width:1187px){
  
  
  .back-image2 {
    position: relative;
   
    width:100vw;
    height:100%;
    background-image: url(../Assets/Backgroundgoal-02.svg);
    object-fit: cover;
    height:90vh;
    background-repeat: no-repeat;
   
    
  }



}






@media only screen and (max-width:766px){
  .goals{
    width: 80.3%;
    margin:0.5%;
  margin-top:2%;
    height:100%;
   
    position: relative;
    margin:0 auto;
  }
  .goalsimages1{
  margin-top:5%;

}
.Allgoals{
  justify-content: center;
  display: grid;

}

.titlepic{
  font-size: 4.5vw;
  color: #072438;
 
}
.contentpic{
  font-size: 4.5vw;
  color: #072438;

}

.titlegoal{
  font-size: 4.5vw;
  text-align: center;
}
.divvvgoal{
  top:3%;
}
}



@media only screen and (max-width:588px){
  .titlepic{
      font-size: 5.2vw;
      color: #072438;
     
  }
  .contentpic{
      font-size:4.8vw;
      color: #072438;
    
  }
.picdivdisplay{
  padding-left: 0%;
  padding:2%;
}

}
@media only screen and (max-width:767px){
  #trtr{
    padding-top:5%;
  }
}
