#joinusdiv{

display: flex;
flex-direction: row;


}

#firstjoinusdiv{

    background-color: red;

}


#secondjoinusdiv{
  
    background-color: green;
    
}


#waitingbuttonsjoin{
    background-color: black;
    color: white;
    padding:2%;
    border:none;
    width:120px;cursor: pointer;
    transition: 0.5s all ease;
    display: flex;
    justify-content: center;
        align-items: center;
      }
    
      #waitingbuttonsjoin:hover{
        width:120px;
        background-color:#939DA6;
        color: black;
        padding:2%;
        transition: 0.5s all ease;
        border:none;
          }

          .marginnjoin{
              margin-left:2%;
          }

          .alljoinbuttons{
              display: flex;
              flex-direction: row;
          }
.alljoincontent{
    display: flex;
    flex-direction: row;
}
.imagephone {
               
    height: 210px;
    margin-top: 5%;}
    #closebtn{
        margin-top: 3%;

    margin-left: 95%;
          }

          @media only screen and (max-width:542px){

            #closebtn{
                margin-top: 5%;
        
            margin-left: 90%;
                  }

            .alljoincontent{
                display: flex;
                flex-direction:column-reverse;text-align: center;
            }
            .alljoinbuttons{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
.marginnjoin{
    margin-top: 2%;
    margin-left: unset;
}
            .imagephone {
               
                height: 200px;
    width: 70%;
    margin: 0 auto;
            }
          }
    

          @media only screen and (max-width:351px){
            #closebtn{
                margin-top: 5%;
        
            margin-left: 90%;
                  }
            .imagephone {
               
                height: 110px;
    width: 80%;
    margin: 0 auto;
            }
          }

       
        #signinbutton:hover{
            background-color: #939DA6;
            transition: 0.5s all ease-in-out;
        }


        @media only screen and (max-width:542px){
            .imagephone{
                display: none;
            }
        }