#GetStarted{
 
  
  
 overflow: hidden;
 position: relative;

  
  }
  
  #getstartedbutton{
    background-color:white;
    margin-left:5vw;
    padding:1.3%;
    border:none;
    cursor:pointer;
    transition:0.5s all ease;
    font-size: 1.4vw;

  }

  #getstartedbutton:hover{
    background-color:#939DA6;
    margin-left:5vw;
    color:white;
    transition:0.5s all ease;
    padding:1.3%;
    font-size: 1.4vw;
    border:none;
    cursor:pointer;
    
  }





  
  
.divvvstart {
   position: absolute;
   top: 30%;
   width: 53%;
   height: 100%;
   
   
 
 }
 @media only screen and(max-width:576px){
   
  
.divvvstart {
  position: absolute;
  top: 30%;
  width: 53%;
  height: 100%;
  
  

}
 }
 
 
 
 
 #startbackimage {
   position: relative;
  
   width:100%;
   height:100%;
   background-image: url(../Assets/getstarted1-01.svg);
   object-fit: cover;
  
   

 }
 #startbackimage img {
  width:100%;
   height: 100%;
  
 }
 
 @media only screen and (min-width:978px){
 .titlestart{
     font-size: 4.5vw;
     font-weight: 700;
     margin-left:5vw;
     
 }


 .fontsize{
  font-size: 2vw;
  font-weight: 500;
  margin-left:5vw;
 }
 #bottom{
  text-align: center;
  margin:0 auto;
  width:100%;
  color: #072430;
  position: absolute;
  bottom: 0;
  font-size: 1.7vw;
  background-color:#939DA6;
  padding:1%;
    }
  
    #mouse{
      width:1.7%;
      height: 1.7%;
    }
  
}

@media only screen and (max-width:977px){
  #bottom{
    text-align: center;
    margin:0 auto;
    width:100%;
    color: #072430;
    position: absolute;
    bottom: 0;
    font-size: 2vw;
    background-color:#939DA6;
    padding:1%;
      }
    
      #mouse{
        width:1.3%;
        height: 1.3%;
      }
    
  .titlestart{
      font-size: 4.5vw;
      font-weight: 700;
      margin-left:5vw;
      
  }
 
 
  .fontsize{
   font-size: 3.5vw;
   font-weight: 500;
   margin-left:5vw;
  }
  #getstartedbutton {
    background-color: white;
    margin-left: 5vw;
    padding: 1.3%;
    border: none;
    cursor: pointer;
  z-index: 1;
    position: relative;
    transition: 0.5s all ease;
    font-size: 2.6vw;}

    #getstartedbutton:hover{
      background-color:#939DA6;
      margin-left:5vw;
      color:white;
      transition:0.5s all ease;
      padding:1.3%;
      font-size: 2.6vw;
      border:none;
      cursor:pointer;
      
    }

  
 }

 @media only screen and (min-width:540px) and (max-width:967px){
   
  .fontsize{
    font-size: 2vw;
    font-weight: 500;
    margin-left:5vw;
   }

   #getstartedbutton {
    background-color: white;
    margin-left: 5vw;
    padding: 1.3%;
    border: none;
    cursor: pointer;
    transition: 0.5s all ease;
    font-size: 2.1vw;}

    #getstartedbutton:hover{
      background-color:#939DA6;
      margin-left:5vw;
      color:white;
      transition:0.5s all ease;
      padding:1.3%;
      font-size: 2.1vw;
      border:none;
      cursor:pointer;
      
    }


 }
  
#startbackimage {
    position: relative;
   
  
    height:98vh;
    display: flex;
    flex-direction: row;
  }

  @media only screen and (max-width:967px){
    #startbackimage {
      position: relative;
     
      
    height:60vh;
      display: flex;
      flex-direction: row;
    }
  }
  #startbackimage svg {
    width: inherit;
    height: inherit;
   
  }
  



  #bottomdiv{
    position: absolute;
    bottom:0;
    height:11vh;
    text-align: center;
  }


  #quote{
    position: absolute;
    z-index: 30;
   color:white;
    top: 5%;


    margin: 0 auto;
  
  }



  
 

 
  #startbackimage.load-end-phase-1   {
svg{
 
  .g {
   
        #Rectangle_798{

   
      animation: swing1 ease-out 1s forwards;
    }

        #Path_1464{
          animation: swing2 ease-out 1s forwards;
     
        
    }

    #Subtraction_1{
      animation: swing3 ease-out 1s forwards;
     
    }
    #phone_2{
      animation: swing4 ease-out 1s forwards;
    }

    #phone-phtot{
      animation: swing5 ease-out 1s forwards;
    }



   }
}


    
  }
  @keyframes swing1 {
    0% {transform: translate(-100%, 0);
     }
    100% { transform: translate(-30 -4433)}
  }
  
  @keyframes swing2 {
  
    0% {  transform :translate(-2245.453px , -5429px);;
    }
   100% { transform:  translate(-1445.453px, -5429px)  }
 
  }






  @keyframes swing3 {
  
    0% {  transform :translate(2958.792px , -5387px);
    }
   100% {transform :translate(2458.792px , -5387px)  }
 
  }

  @keyframes swing4 {
  
    0% {   transform :matrix(-0.839, 0.545, -0.545, -0.839, 2509.7, -4840.757);
    }
   100% { transform :matrix(-0.839, 0.545, -0.545, -0.839, 2009.7, -4840.757);  }
 
  }
  @keyframes swing5 {
  
    0% {
       transform:matrix(0.848, -0.53, 0.53, 0.848, 1262, -4907.723)
    }
   100% {    transform:matrix(0.848, -0.53, 0.53, 0.848, 662, -4907.723)  }
 
  }
  
 
 #imagecarid{
  width:140%;
  height:100%;
  margin-left:-30%;

 }
  


  @media only screen and (max-width:590px){
    #bottom{
      text-align: center;
      margin:0 auto;
      width:100%;
      color: #072430;
      position: absolute;
      bottom: 0;
      font-size: 0.8em;
      background-color:#939DA6;
      padding:1%;
        }
      
  }

  #carrstyle{
    position:absolute;
    top:10%;
    right:4%;
    width:42%;
    height:90%
  }

  
  @media only screen and (max-width:650px){
    #startbackimage {
      position: relative;
      height: 100vh;
      display: flex;
      flex-direction: row;
  }

  .divvvstart {
    position: absolute;
    top: 72%;
    width: 100%;
    text-align: center;
    height: 100%;
}
#carrstyle {
  position: absolute;
  top: 3%;
 
  width: 150%;
  margin-left: -28%;
  
  
}


#imagecarid{
  width: 100%;
  height: 90%;
   margin-left: 17% !important;
  margin: 0 auto;
  
  right:0
}
  }


  @media only screen and (max-width:651px){
    #imagecarid {
      width: 70%;
      height: 54%;
      margin-left: 32%!important;
      margin: 0 auto;
      right: 0;}

      .titlestart {
        font-size: 8vw;
        font-weight: 700;
        margin-left: 5vw;}

        .fontsize {
          font-size: 5vw;
          font-weight: 500;
          margin-left: 5vw;
      }
      .divvvstart {
        position: absolute;
        top: 50%;
        width: 98%;
        text-align: center;
        height: 100%;
    }
    #imagecarid {
      width: 100vw;
     
       margin-left: 0% !important;
      /* margin: 0 auto; */
      /* right: 0; */
     
  }
  #carrstyle {
    position: absolute;
  padding-top:5%;
    top: 3%;
    width: 100%;
    /* margin-left: -28%;*/
    right:0%;

  }

  #getstartedbutton{
    font-size: 3.5vw;
    .titlestart {
      font-size: 8vw;
      font-weight: 700;
      margin-left: 5vw;}

      .fontsize {
        font-size: 5vw;
        font-weight: 500;
        margin-left: 5vw;
    }
  }
  #startbackimage{
    height:90vh;
  }

  }

@media only screen and (max-width:651px) and (min-width:468px){
  .titlestart {
    font-size: 6vw;
    font-weight: 700;
    margin-left: 5vw;}

    .fontsize {
      font-size: 4vw;
      font-weight: 500;
      margin-left: 5vw;
  }

}
  