@media only screen and (min-width:968px){
.dashboardiv{
    
    height: 100vh;
   
    background-color: #FAFAFA;
    margin-left:250px;
    padding-left:2%;
    padding-top:3%;
}


.comingsoon{
    width: 49%;
    margin:0.5%;
    float: left;
    height:95%;
   background-color:white;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}
.containercomingsoon{
    width:90%;
    height:30vh;
    
    margin-top:2%;
}
.matches{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    margin-top:2%;
    width:20vh;
    padding:4%;
    background-color: white;
    margin-left: 0.5%;
}
}
@media only screen and (max-width:967px){
    .matches{
        margin-left: 0.5%;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
        margin-top:2%;
        width:20vh;
        padding:4%;
        background-color: white;
    }
    .containercomingsoon{
        width:90%;
        height:35vh;
        margin-top:2%;
        display: flex;
        flex-direction: column;
    }
.comingsoon{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    width: 98%;
    margin:0.5%;
    float: left;
    height:95%;
   background-color:white;
    position: relative;
}
    .dashboardiv{
        padding-top:3%;
        padding-left:2%;
      
        height: 100vh;
      
        background-color: #FAFAFA;
        margin-left:0px;
    }
}

.hr{
    width:90%;
    background-color: grey;
}
