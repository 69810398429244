#overlay {
    position: fixed;
    
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
    cursor: pointer;
  }
  
  #newsletterdiv{
      margin:0 auto;
      background-color: #fff;
      width:fit-content;
      height: fit-content;
      padding:3%;
      top: 50%;
      left: 50%;
     
      position: absolute;
      transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
    
  }

  #input{
    width: 94%;
    background-color: #E9E9E9;
    border: none;
    padding: 3%;
    margin-top: 4%;
  }
#p{
  font-weight: normal;
  margin:0 auto;
  text-align: center;
}
  #p1{
font-weight:900;
font-size: 2rem;

  }
  #p2{
    font-weight: 700;
    font-size: 1rem;

  }

  #subscribebutton{
      margin:0 auto;
      margin-top: 10%;
    border: none;
    background-color: black;
    color: white;
   display: block;
    width: 60%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-weight: 500;
    font-size: 1rem;

  }

  #signinbutton{
    margin:0 auto;
    margin-top: 10%;
  border: none;
  background-color: black;
  color: white;
 display: block;
  width: 60%;
  padding-top: 2%;
  padding-bottom: 2%;
  font-weight: 500;
  font-size: 1rem;

}
