



#carouselsection {
  background-image: url(../Assets/Background.svg);
  object-fit: cover;
  height: auto;
}

.back-imagecarousel{
  background-image: url(../Assets/Background.svg);
  object-fit: cover;
  position: relative;
  width:100%;
  height: 150vh;
}

@media only screen and  (max-width:962px){
  

  .back-imagecarousel{
    background-image: url(../Assets/Background.svg);
    object-fit: cover;
    position: relative;
    width:100%;
    height: 90vh;
  }
  
  }
  
  
  
  
  
  
@media only screen and  (max-width:496px){
  

.back-imagecarousel{
  background-image: url(../Assets/Background.svg);
  object-fit: cover;
  position: relative;
  width:100%;
  height: 50vh;
}

}








.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 1000px;
  height: 600px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  
  display: none;
 

 
}
#carouselsection{
 
  height:auto;
  position: relative;
  overflow: hidden;
}


#leftt {
   
   
  width: 101%; /* If you make it 100%, you get a bit of black showing along the diagonal */
 
  background: #99b4d3;
 
  clip-path: polygon(0% 0%, 100% 0%, 0 100%);
}

#rightt {
  position: absolute;
  
  width: 90%;
 
  background: #d9b596;
 
  clip-path: polygon(100% 100%, 0% 100%, 100% 0%);
}

#rightreviewss{
  color: black;
  height: fit-content;
  
  float: inline-end;
  margin-top: 15%;
  position: absolute;
  left: 60%;
  bottom: 45%;
  font-size: 2vw;
  
}


#wrapperrr{
  width: 100%;
    height: auto;
    background: #111;
    display: flex;
    position: relative;
    
}



.wrapperrr{

    height: auto;
    background: #F4F5F8;
    display: flex;
    position: relative;
    transition-duration: 1s;
    transform: scale(1);
   opacity: 1;
   position: absolute;
   color: black;
}


.wrapperrrdiv{
  
    height: auto;
    background: #F4F5F8;
    color: black;
    display: flex;
    position: relative;
    transition-duration: 1s;
    transform: scale(1);
    visibility:hidden;
    opacity: 0;
    position: absolute;
   
}
   




.slide.active {
 
  transition-duration: 1s;
  transform: scale(1);
  display: "";

}

.slideactive{
  margin:0 auto;
transition: 5s all;

}



.slidenotactive{
  margin-left:-200%;
  transition: 5s all;


}





.activereview{
  
  -webkit-transform:translate(0%);
  transform: translate(0%);
  transition: 2s all ease-in-out;
  opacity: 1;
  

}
.notactivereview{
  -webkit-transform:translate(60%);
  transform: translate(60%);
  transition: 2s all ease-in-out;
  opacity: 0 ;
 
  

}



.activereview2{
  
  -webkit-transform:translate(-57.5%);
  transform: translate(-57.5%);
  transition: 2s all ease-in-out;
  opacity: 1;
  

}
.notactivereview2{
  -webkit-transform:translate(-100%);
  transform: translate(-100%);
  transition: 2s all ease-in-out;
  opacity: 0 ;
 
  

}



.activereview3{
  
  
  transform: translate(-50.5%);
  transition: 1.84s all ease-in-out;
  opacity: 1;
  

}
.notactivereview3{
 
  transform: translate(-100%);
  transition: 2s all ease-in-out;
  opacity: 0 ;
 
  

}


.activereview4{
  
  
  transform: translate(-11%);
  transition: 1.8s all ease-in-out;
  opacity: 1;
  

}
.notactivereview4{
 
  transform: translate(-60%);
  transition: 2s all ease-in-out;
  opacity: 0 ;
 
  

}


.carousel-indicators{
 
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
 margin-top:50% !important;
    margin-top:25%;
    list-style: none
}

.carousel-indicators li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 35px;
  cursor: pointer;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
}

  .carousel-indicators .active {
    background-color: black;}




    .cls-1{fill:#072438;}
    
    .cls-2{
      stroke:#000;stroke-miterlimit:10;
    
    
    
    }
    
    .cls-3{fill:none;}






    .carousel-indicatorsss{
 
      z-index: 999;position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding-left: 0;
     
      
     
   
      padding-top:2%;
      list-style: none;
    
  }
  
  .carousel-indicatorsss li {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 35px;
    cursor: pointer;
    height: 5px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
  }
  
    .carousel-indicatorsss .active {
      background-color: black;}
  
  
      .svgwrapperrr{
        visibility: unset; 
        width: 85vw;
    margin: 0px auto;
  
    top: 20%;
    margin-left: 5% !important;
  
 
      }

      .svgwrapperrrdiv{
        display: block;
        position: absolute;
 
      }
  
  #newclasstransform{
    transform: translate(-1000px, 0px);
    transition: 0.5s all ease-in-out;
  }
  
  #newclasstransform2{
    transform: translate(-800px, 0px);
    transition: 0.5s all ease-in-out;
  }
  
  #newclasstransform3{
    transform: translate(-150px, 0px);
    transition: 0.5s all ease-in-out;
  }

  




@media only screen and (min-width:1001px){

  
.activedivv6{
  
 
  transform: translate(10%,0px);
  transition: 2s all ease-in-out;
  opacity: 1;
  font-size: 2.5em;
  
 
  

}
.notactivedivv6{
 
  transform: translate(-60%, 0px);
  transition: 2s all ease-in-out;
  opacity: 0;
  font-size: 2.5em;
  
 
  

}

  .activediv6{
  
   
    transform: translate(120%,0px);
    transition: 2s all ease-in-out;
    opacity: 1;
    font-size: 2.5em;
    
   
    
  
  }
  .notactivediv6{
    
    transform: translate(180%, 0px);
    transition: 2s all ease-in-out;
    opacity: 0;
    font-size: 2.5em;
    
   
    
  
  }

  
.activediv{
  
  -webkit-transform:translate(0%,0px);
  transform: translate(0%,0px);
  transition: 2s all ease-in-out;
  opacity: 1;
  font-size: 2.5em;
  
 
  

}
.notactivediv{
  -webkit-transform: translate(-60%, 0px);
  transform: translate(-60%, 0px);
  transition: 2s all ease-in-out;
  opacity: 0;
  font-size: 2.5em;
  
 
  

}


.activediv1{
  
  -webkit-transform:translate(50%,0px);
  transform: translate(50%,0px);
  transition: 2s all ease-in-out;
  opacity: 1;
  
  font-size: 2.5em;
  

}
.notactivediv1{
  -webkit-transform:translate(100%, 0px);
  transform: translate(100%, 0px);
  transition: 2s all ease-in-out;
  opacity: 0;
  font-size: 2.5em;
  
 
  

}




}

@media only screen and (max-width:1000px){
  .activediv6{
  
   
    transform: translate(118%,0px);
    transition: 2s all ease-in-out;
    opacity: 1;
    font-size:0.7em
   
    
  
  }
  .notactivediv6{
  
    transform: translate(180%, 0px);
    transition: 2s all ease-in-out;
    opacity: 0;
    font-size:0.7em
    
   
    
  
  } 

  
.activediv{
  
  -webkit-transform:translate(0%,0px);
  transform: translate(0%,0px);
  transition: 2s all ease-in-out;
  opacity: 1;
  font-size:1em
 
  

}
.notactivediv{
  -webkit-transform: translate(-60%, 0px);
  transform: translate(-60%, 0px);
  transition: 2s all ease-in-out;
  opacity: 0;
  font-size:1em
 
  

}


.activediv1{
  
  -webkit-transform:translate(50%,0px);
  transform: translate(50%,0px);
  transition: 2s all ease-in-out;
  opacity: 1;
  font-size:1em
 
  

}
.notactivediv1{
  -webkit-transform:translate(100%,0px);
  transform: translate(100%, 0px);
  transition: 2s all ease-in-out;
  opacity: 0;
  font-size:1em
 
  

}


.activedivv6{
  
 
  transform: translate(12%,0px);
  transition: 2s all ease-in-out;
  opacity: 1;
  font-size:0.7em
 
  

}
.notactivedivv6{
  
  transform: translate(-60%, 0px);
  transition: 2s all ease-in-out;
  opacity: 0;
  font-size:0.7em
 
  

}


}

#reviewtitle{
  text-align: center;
  color:#ad1f23;
  font-size: 2.4vw;
  margin-bottom:3%;
}

@media only screen and (max-width:767px){
  #reviewtitle{
    text-align: center;
    color:#ad1f23;
    font-size: 4vw;
  }
}


#topdiv{
  top:40%;
}

@media only screen and (max-width:764px){
  
#topdiv{
  top:45%;
}
}
@media only screen and (max-width:520px){
  .carousel-indicatorsss{
    padding-top: 0%;
  }
}