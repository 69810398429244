@media only screen and (min-width:968px){
    .profilediv{
       
        height: 100vh;
        padding-left:2%;
    padding-top:3%;
        background-color: #FAFAFA;
        margin-left:250px;
    }
    .containerinfo2{
        width:70%;
        height:auto;
        
        margin:0 auto;
        text-align: center;
    }
    .imageuser{
        width:15vw;
        height:15vw;
        border-radius:60%;
    }
    .information{
        padding-left:5%;
        height:14vw;
        width:60vw;
        margin-left:2%;
        text-align: left;
        padding-top:5%;
    }
    .photo{
        width: 31.7%;
        margin:0.8%;
        float: left;
        height:30vh;
       
        position: relative;
    }
    }
    @media only screen and (max-width:967px){
        .photo{
            width: 31.7%;
            margin:0.8%;
            float: left;
            height:20vh;
           
            position: relative;
        }
        .information{
            padding-top:5%;
            padding-left:5%;
            height:23vw;
            width:60vw;
            margin-left:2%;
            text-align: left;
        }
        .imageuser{
            width:23vw;
            height:23vw;
            border-radius:60%;
        }

        .containerinfo2{
            width:100%;
            height:auto;
           
            margin:0 auto;
            text-align: center;
        }

        .profilediv{ 
            height: 100vh;
            padding-left:2%;
            padding-top:3%;
            background-color: #FAFAFA;
            margin-left:0px;
        }
    }

    .infocontainer {
        width:90%;
        height:fit-content;
      
        margin-top: 2%;

    }


    .allphotos{
        width: 100%;
        height:auto;
     
       overflow: hidden;
       overflow-x: hidden;
        margin: 0 auto;
    }

    #newdiv{
        width:94.5;
        display:flex;
        flex-direction:row;
        padding:3%;
    }

    
    @media only screen and (max-width:600px){

        .information{
            padding-left:0%;
            height:14vw;
            width:60vw;
            margin-left:0%;
            text-align:center;
            padding-top:5%;
        }
        .containerinfo2{
            width:100%;
            height:auto;
            
            margin:0 auto;
            text-align: center;
        }
        #newdiv{
            width:100%;
            display:flex;
            flex-direction:column;
            padding:3%;
            align-items: center;
            text-align: center;
            height:45vh
        }
    
    }