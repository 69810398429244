#AppFeature{
 
  
   
 /*  background-color: green;*/
   
   background-color: #E9E9E9;
  overflow: hidden;
  position: relative;
 

   }

   
.divvv1 {
    position: absolute;
    top: 8%;
    width: 100%;
    height: 100%;
    
  
    text-align: center;
    align-items: center;
  }
  
  
  
  .back-image1 {
    position: relative;
   
    width:100%;
    height:100%;
  }
  
  
  @media only screen and (min-width:589px){
  .titleapp{
      font-size: 2.4vw;
      font-weight: 700;
      color:#ad1f23;
  }}
  
  @media only screen and (max-width:588px){
    .titleapp{
        font-size: 4vw;
        font-weight: 700;
        color: #ad1f23;
    }}


  .Allfeatures{
    width: 90vw;
    height:auto;
 
   overflow: auto;
   overflow-x: hidden;
    margin: 0 auto;
  }

  .features{
    width: 29%;
    margin:2%;
    float: left;
    height:90%;
   
    position: relative;
  }

  .bubbles1{
      width:65%;
      float: right;
  }
  
  .bubbles2{
    width:65%;
    margin-top:20%;
}
.bubbles4{
  width:80%;
  margin-top:20%;
}




.phone{
    width:100%;
}

@media screen and (max-width:768px){
    .bubbles1{
        width:80%;
        float: right;
    }
    
    .bubbles2{
      width:80%;
      margin-top:20%;
  }
  .bubbles4{
    width:80%;
    margin-top:20%;
}
  
}
@media only screen and (min-width:589px){
  .back-image1 img {
    width:100vw;
     height: 100%;
    
   }
}



@media only screen and (max-width:588px){
  .imageresponsive{
    height: 100%;
    width: 130vw;
  }
}

@media only screen and (max-width:650px){
  #topbutton{
    z-index:2;
    position:fixed;
    bottom:5%;
    right:10%;
    transform:scale(3);
    color:#ad1f23;
  }
  
}

@media only screen and (max-width:657px){
.phone{
  margin-top:25%;
}
  .bubbles1{
width:100%;
  }
  .bubbles2{
    width:100%;
  }

  .bubbles4{
    width:122%;
  }

  


}

@media only screen and (max-width:657px) and (min-width:585px){
  .bubbles1{
    width:80%;
      }
      .bubbles2{
        width:80%;
      }
      .bubbles4{
        width:80%;
      }
}
@media only screen and (max-width:585px){
  .waitcontent p {
    font-weight: 500;
    font-size: 4vw !important;
}
#titlewait{
  font-size: 5vw;
}
}